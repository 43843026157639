<template>
  <svg
    width="17"
    height="19"
    viewBox="0 0 17 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 2C0 0.89543 0.895431 0 2 0H9V4C9 5.65685 10.3431 7 12 7H16V11H11C9.89543 11 9 11.8954 9 13V18H2C0.895431 18 0 17.1046 0 16V2Z"
      fill="currentColor"
    />
    <path
      d="M11 4V0.0829585C13.5125 0.504477 15.4955 2.4875 15.917 5H12C11.4477 5 11 4.55228 11 4Z"
      fill="currentColor"
    />
    <path
      d="M11 16C11 15.4477 11.4477 15 12 15H13V14C13 13.4477 13.4477 13 14 13C14.5523 13 15 13.4477 15 14V15H16C16.5523 15 17 15.4477 17 16C17 16.5523 16.5523 17 16 17H15V18C15 18.5523 14.5523 19 14 19C13.4477 19 13 18.5523 13 18V17H12C11.4477 17 11 16.5523 11 16Z"
      fill="currentColor"
    />
  </svg>
</template>
<script>
import { Vue } from "vue-class-component";

export default class BookingRetail extends Vue {}
</script>
